// ShareASale 埋点 BNTO-963
export function shareASalePurchaseSuccessTracking({orderId, amount}) {
  if (!document.getElementById('_SHRSL_img_1')) {
    const img = document.createElement('img');
    img.id = '_SHRSL_img_1';
    img.src = `https://www.shareasale.com/sale.cfm?tracking=${orderId}&amount=${amount}&merchantID=147993&storeID=2&sscidmode=6&sscid=clickId&v=dev1.5&transtype=sale`;
    img.width = 1;
    img.height = 1;
    document.body.appendChild(img);
  }
}
