<script setup>
import {useBaseStore} from "~/stores/base";
import {orderCreate, orderPrePayDetail} from "~/api/api.order";
import {CARDS} from "~/utils/card";
import ModuleInput from "~/components/ModuleInput.vue";
import {config} from "~/utils/config";
import {Loading} from "@element-plus/icons-vue";
import {handleStripe} from "~/utils/common";
import {FROM, PATH_PAYMENT_RESULT_CHECK, URL_ACCOUNT_RENTAL_HISTORY} from "~/utils/constants";
import {tiktokPixelTrack} from "~/utils/tiktok-track";
import {shareASalePurchaseSuccessTracking} from "~/utils/tracking";

const BNTOMessage = useNuxtApp().$BNTOMessage
const router = useRouter()

/** emits **/
const emits = defineEmits(["back"])

/** props **/
const props = defineProps({
  product: {
    default: {},
    required: true
  },
  orderId: {
    default: "",
    required: true
  }
})

const promoCode = ref("")
const promoCodeError = ref(false)
const promoCodeErrorTips = ref("")
const promoCodeValid = ref(false)
const point = ref("")
const pointError = ref(false)
const pointErrorTips = ref("")
const pointValid = ref(false)

const paymentInfo = ref({})
const summary = ref({})
const totalBonus = ref("")

const isLoading = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)

/** watch **/
watch(promoCode, () => {
  promoCodeValid.value = false
})
watch(point, () => {
  pointValid.value = false
})

const handleBack = () => {
  emits("back")
}
const getDetail = async () => {
  try {
    const res = await orderPrePayDetail({
      couponCode: promoCode.value,
      scene: "UNIT",
      productItemId: props.product.productItemId,
      point: point.value
    })
    console.log("orderPrePayDetail", res.result)
    if (promoCode.value && point.value) {
      promoCodeValid.value = true
      pointValid.value = true
      // ElMessage.success({message: "Promo Code and Bonus Credit are Valid"})
    } else if (promoCode.value) {
      promoCodeValid.value = true
      // ElMessage.success({message: "Promo Code is Valid"})
    } else if (point.value) {
      pointValid.value = true
      // ElMessage.success({message: "Bonus Credit is Valid"})
    }
    paymentInfo.value = res.result.billingInfo
    summary.value = res.result.summary
    totalBonus.value = res.result.totalBonus
  } catch (e) {
    console.error(e)
    switch (e.code) {
      case config.BUSINESS_CODE.INVALID_PROMO_CODE:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PROMO_CODE_USED:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PROMO_CODE_EXCLUDED_PRODUCT:
        promoCodeError.value = true
        promoCodeErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.INSUFFICIENT_POINTS:
        pointError.value = true
        pointErrorTips.value = e.message.content
        break;
      case config.BUSINESS_CODE.PAYMENT_RESULT_CHECK:
        const {orderId} = e.result
        const bntoOrderId = props.orderId
        const returnUrl = `${URL_ACCOUNT_RENTAL_HISTORY}?orderId=${bntoOrderId}&from=${FROM.UNIT_PURCHASE}`
        await router.push(`${PATH_PAYMENT_RESULT_CHECK}?orderId=${orderId}&redirect_uri=${encodeURIComponent(returnUrl)}`)
        break;
    }
  }
}
const handlePromoCodeBlur = () => {
  // reset
  promoCodeValid.value = false
  promoCodeError.value = false
  promoCodeErrorTips.value = ""
}
const handlePromoCodeClearClick = async () => {
  promoCodeValid.value = false
  await getDetail()
}
const handlePromoCodeApplyClick = async () => {
  if (!promoCode.value) return
  await getDetail()
}
const handlePointBlur = () => {
  // reset
  pointValid.value = false
  pointError.value = false
  pointErrorTips.value = ""
}
const handlePointClearClick = async () => {
  pointValid.value = false
  await getDetail()
}
const handlePointApplyClick = async () => {
  if (!point.value) return handlePointClearClick()
  if (!Number.isInteger(Number(point.value))) {
    pointError.value = true
    pointErrorTips.value = "Please enter an integer. You cannot exceed your balance."
    return
  }
  if (Number(point.value) === 0) {
    pointError.value = true
    pointErrorTips.value = "Please enter a non-zero integer"
    return
  }
  if (Number(point.value) < 0 || Number(point.value) > Number(totalBonus.value)) {
    pointError.value = true
    pointErrorTips.value = "Invalid Bonus Credit"
    return
  }
  await getDetail()
}
const handlePurchase = async () => {
  if (isLoading.value) return
  isLoading.value = true
  // props.orderId: BNTO orderId, not unit order
  const redirect_uri = `${URL_ACCOUNT_RENTAL_HISTORY}?orderId=${props.orderId}&from=${FROM.UNIT_PURCHASE}`

  try {
    // 商品购买
    const res = await orderCreate({
      businessType: "UNIT",
      pointsDeductAmount: point.value,
      promotionCode: promoCode.value,
      products: [{productItemId: props.product.productItemId, sku: props.product.sku}]
    })

    // 创单成功，进入成功页面
    isLoading.value = false
    console.log("orderCreate", res.result)

    // 埋点
    const {orderId, payIntentClientSecret, paymentMethodId} = res.result
    tiktokPixelTrack("InitiateCheckout", {content_id: orderId})
    shareASalePurchaseSuccessTracking({orderId, amount: summary.value.retailPrice})

    // success = 1
    await router.push(`${PATH_PAYMENT_RESULT_CHECK}?status=1&redirect_uri=${encodeURIComponent(redirect_uri)}`)
  } catch (e) {
    isLoading.value = false
    const {orderId, payIntentClientSecret, paymentMethodId} = e.result
    // 埋点
    tiktokPixelTrack("InitiateCheckout", {content_id: orderId})
    shareASalePurchaseSuccessTracking({orderId, amount: summary.value.retailPrice})

    if (e.code === config.BUSINESS_CODE.STRIPE_EXECUTE) {
      // 去 Stripe
      await handleStripe(payIntentClientSecret, orderId, redirect_uri)
    } else if (e.code === config.BUSINESS_CODE.PAYMENT_RESULT_CHECK) {
      // 去 支付结果校验页面
      await router.push(`${PATH_PAYMENT_RESULT_CHECK}?orderId=${orderId}&redirect_uri=${encodeURIComponent(redirect_uri)}`)
    }
  }
}

onMounted(() => {
  console.log("product", props.product)
  getDetail()
})
</script>

<template>
  <div class="module-unit-purchase-mobile" v-if="isMobile">
    <div class="header">
      <img src="@/assets/img/icon-arrow-left.svg" alt="left" @click="handleBack">
      review your order
    </div>
    <div class="body">
      <div class="body-title">
        own it forever
        <div class="subtitle" v-if="product.discount">Enjoy <span class="green">{{ product.discount }}% off</span>
          retail value
        </div>
      </div>
      <div class="product-info">
        <div class="left">
          <img :src="product.productImages[0].url" alt="cover">
        </div>
        <div class="right">
          <div class="brand">{{ product.brand }}</div>
          <div class="productName">{{ product.productName }}</div>
          <div class="price">
            <span v-if="product.discount">
              <span class="bold">${{ product.salePrice }}</span>
              <span class="delete">${{ product.originalPrice }}</span>
              <span class="discount">({{ product.discount }}% off)</span>
            </span>
            <span v-else>Retail: ${{ product.originalPrice }}</span>
            <img v-if="product.premium" class="premium" src="@/assets/img/icon-diamond.svg" alt="diamond">
          </div>
          <div class="size">
            Size: {{ product.size }}
          </div>
        </div>
      </div>
      <div class="payment-info">
        <div class="title">Payment Details</div>
        <div class="credit-card" v-if="paymentInfo.creditCardNumber">
          <img :src="CARDS[paymentInfo.cardBrand]?.icon" alt="icon-card">
          {{ CARDS[paymentInfo.cardBrand]?.name }} ····{{ paymentInfo.creditCardNumber }}
        </div>
      </div>
      <div class="summary">
        <div class="title">Summary</div>
        <div class="loading" v-if="!summary.totalBilled">
          <el-icon class="is-loading" size="14">
            <Loading/>
          </el-icon>
        </div>
        <template v-else>
          <div class="summary-body">
            <div class="cell">
              <span>Retail Price</span>
              <span>{{ summary.currency }}{{ summary.retailPrice }}</span>
            </div>
            <div class="cell">
              <span>{{ product.discount }}% Discount</span>
              <span class="green">(-{{ summary.currency }}{{ summary.unitDiscountAmount }})</span>
            </div>
            <div class="cell" v-if="summary.couponDeductAmount">
              <span>Promo Code</span>
              <span class="green">(-{{ summary.currency }}{{ summary.couponDeductAmount }})</span>
            </div>
            <div class="cell" v-if="summary.pointsDeductAmount">
              <span>Bonus Credit Applied</span>
              <span class="green">(-{{ summary.currency }}{{ summary.pointsDeductAmount }})</span>
            </div>
            <div class="cell" v-if="summary.taxes">
              <span>Taxes</span>
              <span>{{ summary.currency }}{{ summary.taxes }}</span>
            </div>
            <div class="cell">
              <span>Delivery</span>
              <span>{{ summary.shippingPrice === '0.00' ? 'Free' : summary.currency + summary.shippingPrice }}</span>
            </div>
          </div>
          <div class="total">
          <span>Total</span>
          <span>{{ summary.currency }}{{ summary.totalBilled }}</span>
        </div>
        </template>
      </div>
      <div class="code-container">
        <div class="title">
          <img src="@/assets/img/icon-coupon.svg" alt="coupon">
          Promo Code
        </div>
        <div class="input-container">
          <module-input
            type="text"
            v-model="promoCode"
            name="coupon"
            placeholder="Copy your code here"
            :input-class="promoCodeValid ? 'code-valid' : 'code-input'"
            :error-tips="promoCodeErrorTips"
            :error="promoCodeError"
            @blur="handlePromoCodeBlur"
            :clearable="promoCodeValid"
            :suffix-button="!promoCodeValid"
            :suffix-button-text="'apply'"
            @confirm="handlePromoCodeApplyClick"
            @clear="handlePromoCodeClearClick"
          />
        </div>
      </div>
      <div class="code-container">
        <div class="title">
          <img src="@/assets/img/icon-currency-dollar.svg" alt="icon">
          BNTO Bonus Credit balance {{ summary.currency }}{{ totalBonus }}
        </div>
        <div class="input-container">
          <module-input
            type="text"
            v-model="point"
            name="coupon"
            placeholder=""
            :input-class="pointValid ? 'code-valid' : 'code-input'"
            :error-tips="pointErrorTips"
            :error="pointError"
            @blur="handlePointBlur"
            :clearable="pointValid"
            :suffix-button="!pointValid"
            :suffix-button-text="'apply'"
            @confirm="handlePointApplyClick"
            @clear="handlePointClearClick"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="tips">Purchases are final sale and cannot be returned.</div>
      <div class="btn-container">
        <div class="btn-secondary btn-cancel" @click="handleBack">cancel</div>
        <div class="btn-primary btn-buy" :class="{'btn-disabled': isLoading}" @click="handlePurchase">
          <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
            <Loading/>
          </el-icon>
          buy
        </div>
      </div>
    </div>
  </div>
  <div class="module-unit-purchase-desktop" v-else>
    <div class="header">
      <img src="@/assets/img/icon-arrow-left.svg" alt="left" @click="handleBack">
      review your order
    </div>
    <div class="body">
      <div class="body-title">
        own it forever
        <div class="subtitle" v-if="product.discount">Enjoy <span class="green">{{ product.discount }}% off</span>
          retail value
        </div>
      </div>
      <div class="product-info">
        <div class="left">
          <img :src="product.productImages[0].url" alt="cover">
        </div>
        <div class="right">
          <div class="brand">{{ product.brand }}</div>
          <div class="productName">{{ product.productName }}</div>
          <div class="price">
            <span v-if="product.discount">
              <span class="bold">${{ product.salePrice }}</span>
              <span class="delete">${{ product.originalPrice }}</span>
              <span class="discount">({{ product.discount }}% off)</span>
            </span>
            <span v-else>Retail: ${{ product.originalPrice }}</span>
            <img v-if="product.premium" class="premium" src="@/assets/img/icon-diamond.svg" alt="diamond">
          </div>
          <div class="size">
            Size: {{ product.size }}
          </div>
        </div>
      </div>
      <div class="payment-info">
        <div class="title">Payment Details</div>
        <div class="credit-card" v-if="paymentInfo.creditCardNumber">
          <img :src="CARDS[paymentInfo.cardBrand]?.icon" alt="icon-card">
          {{ CARDS[paymentInfo.cardBrand]?.name }} ····{{ paymentInfo.creditCardNumber }}
        </div>
      </div>
      <div class="summary">
        <div class="title">Summary</div>
        <div class="loading" v-if="!summary.totalBilled">
          <el-icon class="is-loading" size="14">
            <Loading/>
          </el-icon>
        </div>
        <template v-else>
          <div class="summary-body">
            <div class="cell">
              <span>Retail Price</span>
              <span>{{ summary.currency }}{{ summary.retailPrice }}</span>
            </div>
            <div class="cell">
              <span>{{ product.discount }}% Discount</span>
              <span class="green">(-{{ summary.currency }}{{ summary.unitDiscountAmount }})</span>
            </div>
            <div class="cell" v-if="summary.couponDeductAmount">
              <span>Promo Code</span>
              <span class="green">(-{{ summary.currency }}{{ summary.couponDeductAmount }})</span>
            </div>
            <div class="cell" v-if="summary.pointsDeductAmount">
              <span>Bonus Credit Applied</span>
              <span class="green">(-{{ summary.currency }}{{ summary.pointsDeductAmount }})</span>
            </div>
            <div class="cell" v-if="summary.taxes">
              <span>Taxes</span>
              <span>{{ summary.currency }}{{ summary.taxes }}</span>
            </div>
            <div class="cell">
              <span>Delivery</span>
              <span>{{ summary.shippingPrice === '0.00' ? 'Free' : summary.currency + summary.shippingPrice }}</span>
            </div>
          </div>
          <div class="total">
            <span>Total</span>
            <span>{{ summary.currency }}{{ summary.totalBilled }}</span>
          </div>
        </template>
      </div>
      <div class="code-container">
        <div class="title">
          <img src="@/assets/img/icon-coupon.svg" alt="coupon">
          Promo Code
        </div>
        <div class="input-container">
          <module-input
            type="text"
            v-model="promoCode"
            name="coupon"
            placeholder="Copy your code here"
            :input-class="promoCodeValid ? 'code-valid' : 'code-input'"
            :error-tips="promoCodeErrorTips"
            :error="promoCodeError"
            @blur="handlePromoCodeBlur"
            :clearable="promoCodeValid"
            :suffix-button="!promoCodeValid"
            :suffix-button-text="'apply'"
            @confirm="handlePromoCodeApplyClick"
            @clear="handlePromoCodeClearClick"
          />
        </div>
      </div>
      <div class="code-container">
        <div class="title">
          <img src="@/assets/img/icon-currency-dollar.svg" alt="icon">
          BNTO Bonus Credit balance {{ summary.currency }}{{ totalBonus }}
        </div>
        <div class="input-container">
          <module-input
            type="text"
            v-model="point"
            name="coupon"
            placeholder=""
            :input-class="pointValid ? 'code-valid' : 'code-input'"
            :error-tips="pointErrorTips"
            :error="pointError"
            @blur="handlePointBlur"
            :clearable="pointValid"
            :suffix-button="!pointValid"
            :suffix-button-text="'apply'"
            @confirm="handlePointApplyClick"
            @clear="handlePointClearClick"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="tips">Purchases are final sale and cannot be returned.</div>
      <div class="btn-container">
        <div class="btn-secondary btn-cancel" @click="handleBack">cancel</div>
        <div class="btn-primary btn-buy" :class="{'btn-disabled': isLoading}" @click="handlePurchase">
          <el-icon v-if="isLoading" class="is-loading" color="white" size="16" style="margin-right: 8px">
            <Loading/>
          </el-icon>
          buy
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "src/assets/config";

.code-input {
  background-color: $color-gray-50;
}

.code-valid {
  background-color: $color-success-25;
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-unit-purchase-mobile {
  width: 375px;
  height: calc(100vh - $mobile-header-height);
  overflow-y: auto;
  margin-top: $mobile-header-height;
  padding-bottom: $mobile-safari-address-bar-height;

  position: fixed;
  top: 0;
  z-index: 1;
  background-color: white;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    /* Eyebrow/medium */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.056px;
    text-transform: uppercase;

    img {
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 12px;
      width: 42px;
      height: 42px;
      position: absolute;
      left: 16px;
      top: 8px;
      cursor: pointer;
    }
  }

  .body {
    padding: 0 15px 280px 15px;

    .body-title {
      padding: 15px 0;
      /* Eyebrow/large */
      font-family: "TWK Lausanne";
      font-size: 18px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 100% */
      letter-spacing: -0.072px;
      text-transform: uppercase;
      text-align: center;

      .subtitle {
        margin-top: 8px;
        /* Text sm/Regular */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */

        .green {
          color: $color-BNTO-green;
          text-align: center;
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .product-info {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      display: flex;
      gap: 12px;

      .left {
        display: flex;

        img {
          width: 100px;
          height: 140px;
        }
      }

      .right {
        padding: 8px;

        .brand {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */

          // 单行
          word-break: break-all;
          word-wrap: anywhere;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }

        .productName {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          // 单行
          word-break: break-all;
          word-wrap: anywhere;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }

        .price {
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          display: flex;
          align-items: center;
          gap: 2px;

          .bold {
            /* Text xs/Bold */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 18px; /* 150% */
          }

          .delete {
            color: $color-gray-500;
            text-decoration: line-through;
            margin-left: 4px;
          }

          .discount {
            /* Text xs/Bold */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 18px; /* 150% */

            color: $color-BNTO-green;
            margin-left: 4px;
          }

          .premium {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
        }

        .size {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }
      }
    }

    .payment-info {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 16px;
      margin-top: 10px;

      .title {
        overflow: hidden;
        color: $color-gray-500;
        text-overflow: ellipsis;
        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */
        text-align: left;
        padding: 0;
      }

      .credit-card {
        display: flex;
        align-items: center;

        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */

        margin-top: 4px;

        img {
          display: flex;
          width: 34px;
          height: 24px;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        }
      }
    }

    .summary {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 5px 16px;
      margin-top: 10px;

      .title {
        overflow: hidden;
        color: $color-gray-500;
        text-overflow: ellipsis;
        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */
        text-align: left;
        padding: 0;
      }

      .summary-body {
        margin-top: 16px;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;

          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
          margin-bottom: 8px;

          .green {
            color: $color-green-700;
          }
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;

        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */
      }

      .loading {
        height: 154px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .code-container {
      margin-top: 16px;
      padding: 8px;
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

      .title {
        display: flex;
        align-items: center;
        //styleName: Text sm/Bold;
        font-family: "TWK Lausanne";
        font-weight: 650;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        padding: 0;


        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }

      .input-container {
        margin-top: 8px;
        display: flex;
        position: relative;

        .button-apply {
          cursor: pointer;
          flex-shrink: 0;
          width: 58px;
          height: 36px;
          box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

          /* Button/small */
          font-family: "Druk Wide Cy";
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px; /* 111.111% */
          text-transform: uppercase;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .footer {
    // 0.5px border
    box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
    position: fixed;
    bottom: 0;
    width: 375px;
    background-color: white;
    padding: 10px 16px 35px 16px;

    .tips {
      overflow: hidden;
      color: $color-gray-600;
      text-align: center;
      text-overflow: ellipsis;
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
    }

    .btn-container {
      display: flex;
      gap: 10px;
      margin-top: 10px;

      div {
        width: 100%;
      }
    }

  }
}

.module-unit-purchase-desktop {
  width: 375px;
  height: calc(100vh - $desktop-header-height);
  overflow-y: scroll;
  margin-top: $desktop-header-height;

  position: fixed;
  top: 0;
  z-index: 1;
  background-color: white;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    /* Eyebrow/medium */
    font-family: "TWK Lausanne";
    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.056px;
    text-transform: uppercase;

    img {
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 12px;
      width: 42px;
      height: 42px;
      position: absolute;
      left: 16px;
      top: 8px;
      cursor: pointer;
    }
  }

  .body {
    padding: 0 15px 230px 15px;

    .body-title {
      padding: 15px 0;
      /* Eyebrow/large */
      font-family: "TWK Lausanne";
      font-size: 18px;
      font-style: normal;
      font-weight: 650;
      line-height: 18px; /* 100% */
      letter-spacing: -0.072px;
      text-transform: uppercase;
      text-align: center;

      .subtitle {
        margin-top: 8px;
        /* Text sm/Regular */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px; /* 142.857% */

        .green {
          color: $color-BNTO-green;
          text-align: center;
          font-family: "TWK Lausanne";
          font-size: 14px;
          font-style: normal;
          font-weight: 650;
          line-height: 20px; /* 142.857% */
        }
      }
    }

    .product-info {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      display: flex;
      gap: 12px;

      .left {
        display: flex;

        img {
          width: 100px;
          height: 140px;
        }
      }

      .right {
        padding: 8px;

        .brand {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Bold */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 650;
          line-height: 18px; /* 150% */

          // 单行
          word-break: break-all;
          word-wrap: anywhere;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }

        .productName {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          // 单行
          word-break: break-all;
          word-wrap: anywhere;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          line-clamp: 1;
          -webkit-line-clamp: 1;
        }

        .price {
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */

          display: flex;
          align-items: center;
          gap: 2px;

          .bold {
            /* Text xs/Bold */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 18px; /* 150% */
          }

          .delete {
            color: $color-gray-500;
            text-decoration: line-through;
            margin-left: 4px;
          }

          .discount {
            /* Text xs/Bold */
            font-family: "TWK Lausanne";
            font-size: 12px;
            font-style: normal;
            font-weight: 650;
            line-height: 18px; /* 150% */

            color: $color-BNTO-green;
            margin-left: 4px;
          }

          .premium {
            width: 18px;
            height: 18px;
            flex-shrink: 0;
          }
        }

        .size {
          overflow: hidden;
          text-overflow: ellipsis;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }
      }
    }

    .payment-info {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 16px;
      margin-top: 10px;

      .title {
        overflow: hidden;
        color: $color-gray-500;
        text-overflow: ellipsis;
        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */
        text-align: left;
        padding: 0;
      }

      .credit-card {
        display: flex;
        align-items: center;

        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */

        margin-top: 4px;

        img {
          display: flex;
          width: 34px;
          height: 24px;
          justify-content: center;
          align-items: center;
          margin-right: 8px;
        }
      }
    }

    .summary {
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
      padding: 5px 16px;
      margin-top: 10px;

      .title {
        overflow: hidden;
        color: $color-gray-500;
        text-overflow: ellipsis;
        /* Text sm/Bold */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 20px; /* 142.857% */
        text-align: left;
        padding: 0;
      }

      .summary-body {
        margin-top: 16px;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;

          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
          margin-bottom: 8px;

          .green {
            color: $color-green-700;
          }
        }
      }

      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;

        /* Text xs/Bold */
        font-family: "TWK Lausanne";
        font-size: 12px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 150% */
      }

      .loading {
        height: 154px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .code-container {
      margin-top: 16px;
      padding: 8px;
      // 0.5px border
      box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

      .title {
        display: flex;
        align-items: center;
        //styleName: Text sm/Bold;
        font-family: "TWK Lausanne";
        font-weight: 650;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        padding: 0;


        img {
          width: 20px;
          height: 20px;
          margin-right: 4px;
        }
      }

      .input-container {
        margin-top: 8px;
        display: flex;
        position: relative;

        .button-apply {
          cursor: pointer;
          flex-shrink: 0;
          width: 58px;
          height: 36px;
          box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;

          /* Button/small */
          font-family: "Druk Wide Cy";
          font-size: 9px;
          font-style: normal;
          font-weight: 500;
          line-height: 10px; /* 111.111% */
          text-transform: uppercase;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .footer {
    // 0.5px border
    box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
    position: fixed;
    bottom: 0;
    width: 375px;
    background-color: white;
    padding: 10px 16px 35px 16px;

    .tips {
      overflow: hidden;
      color: $color-gray-600;
      text-align: center;
      text-overflow: ellipsis;
      /* Text xs/Regular */
      font-family: "TWK Lausanne";
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px; /* 150% */
    }

    .btn-container {
      display: flex;
      gap: 10px;
      margin-top: 10px;

      div {
        width: 100%;
      }
    }

  }
}
</style>
